import React from 'react';
import PropTypes from 'prop-types';
import { Form, ControlLabel, FormControl } from 'react-bootstrap';
import Select, { Creatable } from 'react-select';
import 'react-select/dist/react-select.css';
import 'react-rangeslider/lib/index.css';
import { compact, find, get, isUndefined, reject } from 'lodash';
import Toggle from 'react-toggle';
import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';

import DetailInfos from './DetailInfos';
import { USER_ROLES } from '../../constants/UserRoles';
import { SelectControl } from '../Common/SelectControl';
import Score from './General/Score';
import Assignee from './General/Assignee';
import Cancelable from './General/Cancelable';
import EditableIfPending from './General/EditableIfPending';
import ResendMailIfPending from './General/ResendMailIfPending';
import ExcludeFromParamTransfer from './General/ExcludeFromParamTransfer';
import Redirect from './General/Redirect';
import BusinessRelevance from './General/BusinessRelevance';
import Permissions from './General/Permissions';

function getWorkguideStatusOptions(codes, language = 'de') {
  return codes
    .filter((c) => ['workguideStatus-1', 'workguideStatus-2'].includes(get(c, 'id')))
    .map((c) => ({ value: get(c, 'id'), code: c, label: get(c, `text.${language}`) }));
}

function convertCodeToOption(codes, language = 'de') {
  return codes.map((c) => ({
    value: get(c, 'id'),
    label: get(c, `text.${language}`),
    code: c
  }));
}
function getArchiveDocumentTypeOptions(codes, language = 'de') {
  return codes.map((c) => ({
    value: get(c, 'id'),
    label: `${get(c, `text.${language}`)} (${get(c, 'number')})`,
    code: c
  }));
}

function getWorkguideCategoryOptions(codes, language = 'de') {
  const idToMoveToTop = 'workguideCategory-11';

  // Separate the item to move to top from the rest of the list
  const itemToMove = find(codes, { id: idToMoveToTop });
  const remainingItems = reject(codes, { id: idToMoveToTop });

  const orderedList = compact([itemToMove, ...remainingItems]);

  return orderedList.map((c) => ({
    code: c,
    value: get(c, 'id'),
    label: get(c, `text.${language}`)
  }));
}

export default class General extends React.Component {
  constructor(props) {
    super(props);

    this.onSspActionChange = this.onSspActionChange.bind(this);
    this.getUserRolesOptions = this.getUserRolesOptions.bind(this);
    this.onPossibleRejectReasonsChange = this.onPossibleRejectReasonsChange.bind(this);
    this.onCamundaValueChange = this.onCamundaConfigChange.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.onDefaultStatusChange = this.onDefaultStatusChange.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    try {
      const userRolesOptions = this.getUserRolesOptions();
      const userRoleSelector = document.getElementsByClassName('workguide-form__user-roles')[0];
      if (userRoleSelector) {
        for (const labelElement of userRoleSelector.getElementsByClassName('Select-value-label')) {
          const label = labelElement.textContent.trim();
          const userRoleOptionByLabel = userRolesOptions.find((userRoleOption) => userRoleOption.label === label);
          if (userRoleOptionByLabel && userRoleOptionByLabel.disabled) {
            const deleteIcon = labelElement.closest('.Select-value').getElementsByClassName('Select-value-icon')[0];
            deleteIcon.style.backgroundColor = '#eceeef';
            deleteIcon.style.color = '#81bdf3';
            labelElement.style.backgroundColor = '#eceeef';
            labelElement.style.color = '#81bdf3';
          }
        }
      }
    } catch (e) {
      console.error('Error accured while updating component', e);
    }
  }

  /**
   * Handle onChange of default status
   *
   * @param  {Object} selected Selected option from select
   *
   * @return void
   */
  onDefaultStatusChange(selected) {
    const { editValueField } = this.props;
    const code = get(selected, 'code');

    editValueField('defaultStatus', 'defaultStatus', code);
  }

  /**
   * Handle onChange of workguide category
   *
   * @param  {Object} selected Selected option from select
   *
   * @return void
   */
  onCategoryChange(selected) {
    const { editValueField } = this.props;
    const code = get(selected, 'code');

    editValueField('category', 'category', code);
  }

  /**
   * Handle onChange of code select
   *
   * @param  {Object} selected Selected option from select
   *
   * @return void
   */
  onCodeChange(key, selected) {
    const { editValueField } = this.props;
    const code = get(selected, 'code');

    editValueField(key, key, code);
  }

  /**
   * Hanel onChange of possible reject reasons select
   *
   * @param  {Array} selected Array of selected options
   *
   * @return void
   */
  onPossibleRejectReasonsChange(selected) {
    const { editValueField } = this.props;
    const values = selected.map((s) => get(s, 'value'));

    editValueField('possibleRejectReasons', 'possibleRejectReasons', values);
  }

  /**
   * Handle onChange of ssb action
   *
   * @param  {Object} selected Selected option from select
   *
   * @return void
   */
  onSspActionChange(selected) {
    const { editValueField } = this.props;

    editValueField('target', 'target', selected.value);
  }

  onCamundaConfigChange(field, value) {
    const { editValueField, workguide } = this.props;
    const current = get(workguide, 'camunda', {});
    const updated = !isUndefined(value)
      ? update(current, { [field]: { $set: value } })
      : update(current, { $unset: [field] });

    editValueField(field, 'camunda', updated);
  }

  /**
   * Return Array of User Roles
   *
   * @return {Array} Array of User Roles
   */
  getUserRolesOptions() {
    const { workguide } = this.props;
    const selectedUserRoles = get(workguide, 'userRoles', []);

    const options = USER_ROLES.map((role) => ({ label: role, value: role, }));
    selectedUserRoles.forEach((selectedRole) => {
      if (!USER_ROLES.find((role) => role === selectedRole)) {
        options.push({ label: selectedRole, value: selectedRole, disabled: true });
      }
    });
    return options;
  }

  render() {
    const {
      workguide, language, editValueField, codes
    } = this.props;
    const {
      orderOrigin = [],
      workguideStatus = [],
      workguideRejectReason = [],
      workguideCategory = [],
      documentFindCode = [],
      archiveDocumentType = []
    } = codes;

    return (
      <div>
        <h3>Allgemeine Informationen bearbeiten</h3>
        <Form style={{ marginTop: 30, width: 600 }}>
          <ExcludeFromParamTransfer
            editValueField={editValueField}
            workguide={workguide}
          />

          <ControlLabel>Workguide Titel</ControlLabel>
          <FormControl
            placeholder="Titel"
            type="text"
            value={get(workguide, `title.${language}`)}
            name="title"
            onChange={editValueField.bind(this)}
          />

          <ControlLabel>Suchbegriffe</ControlLabel>
          {get(workguide, `keyword.${language}`) !== undefined && (
            <Creatable
              multi
              placeholder="Bitte Suchbegriffe für diesen Workguide erfassen"
              value={get(workguide, `keyword.${language}`).map((o) => ({ label: o, value: o }))}
              onChange={editValueField.bind(this, 'keyword', 'keyword')}
              closeOnSelect={false}
            />
          )}
          <ControlLabel>Prozesstyp</ControlLabel>
          <Select
            placeholder="Bitte Suchbegriffe für diesen Workguide erfassen"
            options={[
              { value: 'email', label: 'Email ohne Notiz' },
              { value: 'activity', label: 'Aktivität mit Notiz' },
              { value: 'emailWithActivity', label: 'Email mit Notiz' },
              { value: 'info', label: 'Info' },
              { value: 'custom', label: 'Ausprogrammiert' },
              { value: 'bpf', label: 'BPF Generisch' },
              { value: 'bpfCustom', label: 'BPF Ausprogrammiert' },
              { value: 'approval', label: 'Freigabe' },
              { value: 'ssp', label: 'Self Service Portal' },
              { value: 'createDocument', label: 'Dokument erstellen' },
            ]}
            value={get(workguide, 'type')}
            onChange={editValueField.bind(this, 'type', 'select')}
            clearable={false}
          />

          <BusinessRelevance
            codes={codes}
            editValueField={editValueField}
            workguide={workguide}
          />

          {['email', 'emailWithActivity', 'custom', 'approval'].includes(get(workguide, 'type')) && (
            <div>
              <ControlLabel>Zieladresse (e-Mail)</ControlLabel>
              <FormControl
                placeholder="Bitte Zieladresse Mailprozess angeben"
                type="text"
                value={workguide.target}
                name="target"
                onChange={editValueField.bind(this)}
              />
            </div>
          )}

          <Redirect
            editValueField={editValueField}
            workguide={workguide}
          />

          <Score workguide={workguide} />

          <Assignee
            editValueField={editValueField}
            workguide={workguide}
          />

          {(['activity', 'emailWithActivity', 'custom'].includes(get(workguide, 'type'))) ? (
            <React.Fragment>
              <ControlLabel>Status nach Ausführung (Vorsicht: Muss bei Custom Workguides ausprogrammiert werden)</ControlLabel>
              <br />
              <Select
                value={get(workguide, 'defaultStatus.id')}
                options={getWorkguideStatusOptions(workguideStatus, language)}
                onChange={this.onDefaultStatusChange}
              />
            </React.Fragment>
          ) : null}

          <ControlLabel>Kategorie</ControlLabel>
          <Select
            placeholder="Bitte eine Kategorie auswählen"
            options={getWorkguideCategoryOptions(workguideCategory, language)}
            value={get(workguide, 'category.id')}
            onChange={this.onCategoryChange}
            clearable
          />

          {['emailWithActivity', 'activity', 'approval'].includes(get(workguide, 'type')) && (
            <React.Fragment>
              <ControlLabel>Kontaktart (Standard Interner Auftrag wenn leer (orderOrigin-IA))</ControlLabel>
              <Select
                placeholder="Bitte eine Kontaktart auswählen"
                options={convertCodeToOption(orderOrigin, language)}
                value={get(workguide, 'orderOrigin.id')}
                onChange={(val) => this.onCodeChange('orderOrigin', val)}
                clearable
              />
            </React.Fragment>
          )}

          {['createDocument'].includes(get(workguide, 'type')) && (
          <React.Fragment>
            <ControlLabel>Dokumenttyp</ControlLabel>
            <Select
              placeholder="Bitte eine Dokumenttyp auswählen"
              options={getArchiveDocumentTypeOptions(archiveDocumentType, language)}
              value={get(workguide, 'archiveDocumentType.id')}
              onChange={(val) => this.onCodeChange('archiveDocumentType', val)}
              clearable
            />

            <ControlLabel>Auffind Code</ControlLabel>
            <Select
              placeholder="Bitte eine Auffind Code auswählen"
              options={convertCodeToOption(documentFindCode, language)}
              value={get(workguide, 'documentFindCode.id')}
              onChange={(val) => this.onCodeChange('documentFindCode', val)}
              clearable
            />
          </React.Fragment>
          )}

          <React.Fragment>
            <ControlLabel>Kein Kunde benötigt</ControlLabel>
            <br />
            <Toggle
              checked={workguide.noCustomerRequired === undefined ? false : workguide.noCustomerRequired}
              onChange={editValueField.bind(this, 'noCustomerRequired', 'noCustomerRequired')}
            />
          </React.Fragment>

          <div style={{ marginTop: '10px' }}>
            <ControlLabel>Workguide kann zwischengespeichert werden</ControlLabel>
            <br />
            <Toggle
              checked={get(workguide, 'canBeSaved', true)}
              onChange={editValueField.bind(this, 'canBeSaved', 'canBeSaved')}
            />
          </div>

          <Cancelable
            editValueField={editValueField}
            workguide={workguide}
          />

          {(['activity', 'emailWithActivity'].includes(get(workguide, 'type'))) && (
            <EditableIfPending
              editValueField={editValueField}
              workguide={workguide}
            />
          )}

          {(['emailWithActivity'].includes(get(workguide, 'type'))) && (
            <ResendMailIfPending
              editValueField={editValueField}
              workguide={workguide}
            />
          )}

          <div style={{ marginTop: '10px' }}>
            <ControlLabel>"Änderungen verwerfen" Modal nicht anzeigen</ControlLabel>
            <br />
            <Toggle
              checked={get(workguide, 'noDiscardWarningRequired', false)}
              onChange={() => editValueField('', 'workguidePropery', { key: 'noDiscardWarningRequired', value: !get(workguide, 'noDiscardWarningRequired', false) })}
            />
          </div>

          {['emailWithActivity', 'activity', 'approval', 'custom'].includes(get(workguide, 'type')) && (
            <React.Fragment>
              <ControlLabel>Mögliche Ablehungsgründe</ControlLabel>
              <br />
              <Select
                value={get(workguide, 'possibleRejectReasons', [])}
                options={convertCodeToOption(workguideRejectReason, language)}
                onChange={this.onPossibleRejectReasonsChange}
                multi
              />
            </React.Fragment>
          )}

          <div style={{ marginTop: '10px' }}>
            <ControlLabel><FormattedMessage id="workguidedetail_form_userRolesLabel" /></ControlLabel>
            <SelectControl
              className="workguide-form__user-roles"
              options={this.getUserRolesOptions()}
              multi
              value={get(workguide, 'userRoles', [])}
              onChange={editValueField.bind(this, 'userRoles', 'userRoles')}
              placeholder="workguidedetail_form_userRolesAllOption"
            />
          </div>

          <div style={{ marginTop: '10px' }}>
            <Permissions
              editValueField={editValueField}
              workguide={workguide}
            />
          </div>

          <h4>Camunda Konfiguration</h4>
          <div>
            <ControlLabel>Kein Task / Prozessdaten benötigt</ControlLabel>
            <p>
              Wenn ausgewählt, werden für diesem Workguide keine Task bzw. Prozessdaten benötigt.
              Bei einem Click auf die Aktion in der Aktivitätenübersicht, wird dann direkt der Workguide aufgerufen.
            </p>
            <br />
            <Toggle
              checked={get(workguide, 'camunda.noTaskDataRequired', false)}
              onChange={(ev) => this.onCamundaValueChange('noTaskDataRequired', !get(workguide, 'camunda.noTaskDataRequired', false))}
            />
          </div>

          <hr />
        </Form>
        <ControlLabel>Prozesslaufzeit / Verantwortlich</ControlLabel>
        <DetailInfos
          editValueField={editValueField.bind(this)}
          content={get(workguide, `runtimeAndResponsible.${language}`)}
          targetField="runtimeAndResponsible"
          title="Prozessdetails erfassen"
        />
      </div>
    );
  }
}

General.propTypes = {
  workguide: PropTypes.object,
  language: PropTypes.string,
  editValueField: PropTypes.func,
  codes: PropTypes.object
};

General.defaultProps = {
  workguide: {},
  language: 'en',
  editValueField: () => console.error('no edit func available'),
  codes: {}
};
